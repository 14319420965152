import React, { useState } from "react";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);
    try {
      // Call the backend API endpoint to send password reset email
      const response = await axios.post("/api/user/forgot-password", {
        email,
        role: ["cloud_admin", "super_admin"],
      });
      if (response.status === 200) {
        setSuccessMessage(
          "Password reset instructions have been sent to your email."
        );
        setErrorMessage("");
        setEmail("");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage("User not found.");
          setEmail("");
        } else if (error.response.status === 401) {
          setErrorMessage("Unauthorized.");
          setEmail("");
        } else {
          setErrorMessage("An error occurred.");
          setEmail("");
        }
      } else if (error.request) {
        setErrorMessage("No response received from the server.");
        setEmail("");
      } else {
        setErrorMessage("Error setting up the request.");
        setEmail("");
      }
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 ">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset Password
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setSuccessMessage("");
                  setErrorMessage("");
                }}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm14-10.582A7.962 7.962 0 0120 12h4c0-6.627-5.373-12-12-12v4zm-9 7.291l-3 2.647A8.013 8.013 0 018 12H4c0 1.487.402 2.873 1.044 4.065l3-2.647zm9 2.582l3-2.647A7.96 7.96 0 0120 12h-4c0 1.487-.402 2.873-1.044 4.065l-3-2.647z"
                  ></path>
                </svg>
              )}
              {loading ? "Sending email..." : "Send Reset Email"}
            </button>
          </div>
        </form>
        <div className="text-sm text-gray-600 text-center">
          <Link
            to="/login"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
